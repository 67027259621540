// Variables
@import "../../../sass/variables";

/* モーダルCSS */
.notices-dialog-area {
  display: none;
  position: fixed;
  z-index: 10000; /*サイトによってここの数値は調整 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.notices-dialog-bg {
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.2);
}

.notices-dialog-wrapper {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 50px 30px rgb(0 0 0 / 20%);
  left: 50%;
  width: max-content;
  max-width: 90%;
  min-height: 20%;
  padding: 15px 15px 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 80%;
  overflow: scroll;

  img {
    height: auto;
    width: auto;
  }
}

.close-notices-dialog {
  position: absolute;
  top: 0.5rem;
  right: 0.8rem;
  cursor: pointer;
}
