// Variables
@import "../../../sass/variables";

// カード用ラッパー
.card-wrapper {
  position: relative;
}

// cards 列
.cards-row {
  margin-top: 3rem;
  padding: 0;
  text-align: center;
}

// カード用バックグラウンド白背景
.card-background {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: $theme-main-white;
  box-shadow: 0 3px 2px 0 $shadow-gray;
  width: 100%;
  height: 93%;
}

// カード用コンテナ
.card-container {
  z-index: 3;
}

// カードタイトル
.cards-title {
  width: 88%;
  margin: 0 auto;
  text-align: center;
}

// カルーセル用コンテナ
.carousel-container {
  margin: 15px auto 10px;
  width: 90%;
  position: relative;
}

// 矢印左
.arrow-l {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 45%;
  left: -20px;
}

// 矢印右
.arrow-r {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 45%;
  right: -20px;
}

// もっと見るのラッパー
.more-info {
  width: 89%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

// もっと見る
.more-info-button {
  padding-left: 1rem;
  padding-top: 6px;
  padding-bottom: 6px;
  width: 10rem;
  text-align: left;
  background-color: $theme-accent-black;
  color: $theme-accent-graywhite;
  cursor: pointer;

  &:hover {
    color: $theme-accent-white;
    background-color: $theme-accent-darkgray;
  }
}


// ボタンのデコレーション
.decolation-line {
  position: absolute;
  display: flex;
  bottom: 10px;
  right: 1rem;
  height: 1rem;
  width: 10rem;
  justify-content: flex-end;
  align-items: flex-end;
}

.decolation-line span,
.decolation-line span:after {
  content: "";
  display: block;
  height: 1px;
  width: 40px;
  background-color: $theme-accent-lightgray;
  position: absolute;
}

.decolation-line span {
  right: 3px;
}

.decolation-line span:after {
  bottom: 5px;
  right: -2px;
  width: 15px;
  transform: rotate(45deg);
}

// スマートフォン対応
@media (max-width: 767px) {
  // cards 列
  .cards-row {
    margin-top: 3rem;
  }

  .cards-col {
    padding: 0;
  }

  // カルーセル用コンテナ
  .carousel-container {
    margin-top: 15px;
    width: 100%;
    position: relative;
    overflow: visible;
  }

  .carousel-inner {
    &::-webkit-scrollbar {
      padding-left: 12px;
      width: 10px;
    }
  }

  // お気に入りアイコン
  //.favorite-heart {
  //  position: absolute;
  //  width: 24px;
  //  height: 24px;
  //  top: 5%;
  //  right: 12px;
  //}

  // 矢印
  .arrow-l {
    left: 10px;
    z-index: 1;
    width: 15px;
    height: 15px;
  }
  .arrow-r {
    right: 10px;
    z-index: 1;
    width: 15px;
    height: 15px;
  }

  // もっと見るのラッパー
  .more-info {
    width: 100%;
  }
}
