// Variables
@import "../../../sass/variables";

// トップバナー　列
.top-banner-row {
  background-color: $theme-main-white;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  .top-banner-col {
    img {
      height: auto;
      width: 100%;
    }
  }
}

.slick-dots {
  bottom: -15px;
}

.top-banner-img {
  height: auto;
  width: 100%;
}

.dots_pc {
  position: relative;
  text-align: center;
  margin: 12px 0;
  padding-bottom: 6px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 3rem;
  color: #000 !important;
}


button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.slide-arrow {
  position: absolute;
  top: 50%;
  margin-top: -15px;
}

.prev-arrow-pc {
  left: -40px;
  width: 0;
  height: 0;
  border-left: 0 solid transparent;
  border-right: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.next-arrow-pc {
  right: -40px;
  width: 0;
  height: 0;
  border-right: 0 solid transparent;
  border-left: 15px solid #113463;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
}

.prev-arrow-sp,
.next-arrow-sp {
  display: none;
}

@media (max-width: 767px) {
  .banner-pc {
    display: none !important;
  }

  .banner-sp {
    display: block !important;

    .slick-dots {
      bottom: -5px;
    }
  }

  .dots_sp {
    position: relative;
    text-align: center;
    margin: 0;
  }

  .prev-arrow-pc,
  .next-arrow-pc {
    display: none;
  }
  .prev-arrow-sp {
    left: 20px;
    opacity: 1;
    width: 0;
    height: 0;
    border-left: 0 solid transparent;
    border-right: 15px solid #113463;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    z-index: 10;
  }
  .next-arrow-sp {
    right: 20px;
    opacity: 1;
    width: 0;
    height: 0;
    border-right: 0 solid transparent;
    border-left: 15px solid #113463;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    z-index: 10;
  }
}
