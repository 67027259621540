// Variables
@import "../../../sass/variables";

// 特集の画像
.topics-image {
  width: 95%;
  height: auto;
}

// 特集　列
.topics-row {
  margin-top: 3rem;
}

// 特集のお知らせ　列
.topics-notice-row {
  margin-bottom: 4rem;

  .topic-title-link {
    :hover {
      .topic-title {
        color: #939393;
        text-decoration: underline;
      }
    }
  }
}

.topics-notice-col {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding: 1rem;
  background-color: #dcdcdc;
}

.topics-notice-text {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
}

@media (max-width: 767px) {

  // 特集　列
  .topics-row {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  // 特集　お知らせ　列
  .topics-notice-row {
    margin-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 4rem;
  }

  // 特集　お知らせ　列
  .topics-notice-col {
    padding: .5rem;
  }

  .topics-notice-text {
    align-items: flex-start !important;
  }
}
