// Variables
@import "../../../sass/variables";
.top-block-content{
    display: flex;
    align-items: center;
    box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    padding: 10px 15px;
    margin-bottom: 0;
    cursor: pointer;

    dt{
        width: 40%;
        img{
            width: 100%;
            height: auto;
        }
    }

    dd{
        width: 60%;
        font-size: 13px;
        margin-bottom: 0;
    }
}

.top_blocks {
  img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
}
