// Variables
@import "../../../sass/variables";

// お知らせ一覧　列
.notices-row {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $theme-main-white;
  margin-top: 2.5rem;

  .to-notice-list:hover{
    color: #939393;
    text-decoration: underline;
  }
}

// お知らせ一覧
.notice-items {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: .5rem;
  padding-bottom: .5rem;
  border-bottom: 1px solid $theme-accent-lightgray;

  .notice-text {
    display: inline-block;
    padding-right: 35px;
    line-height: 1.4;
  }

  .notice-text:hover {
    color: #939393;
    text-decoration: underline;
  }
}

.notice-arrow-r {
  position: absolute;
  right: 5px;
}

.marker {
  font-size: 0.6rem;
  height: 18px;

  &.notice-new {
    color: $theme-accent-white;
    background-color: $theme-accent-red;
    padding-top: 1px;
    border-radius: 10px;
    width: 3rem;
    display: inline-block;
    text-align: center;
  }

  .favorite-icon {
    position: absolute;
    right: 7px;
    top: 0;
  }
}

// スマートフォン対応
@media (max-width: 767px) {
  // お知らせ一覧　列
  .notices-row {
    margin-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }

  // お知らせ一覧
  .notice-items {
    position: relative;
    display: block;
    margin-top: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
    background-color: $theme-main-white;
    border: none;
    box-shadow: 0 5px 5px 0 $shadow;
  }

  // 矢印
  .notice-arrow-r {
    display: none;
  }
}
