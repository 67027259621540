// Variables
@import "../../../sass/variables";

// card style
@import "../../common/scss/card.scss";

// top_banners_sp.blade.php style
@import "./top_banners";

// cards.blade.php style
@import "../../common/scss/cards.scss";

// topics.blade.php style
@import "./topics.scss";

// notices.blade.php style
@import "./notices";

// notices_dialog.blade.php style
@import "./notices_dialog";

// blocks.blade.php style
@import "./blocks";

main {
  padding-top: 0;
}

// PCスクロール用スタイル
#nav-pc-scrolled {
  display: none;
}

.banner-sp {
  display: none;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

// トップ全体
.top-wrapper {
  width: 100%;
  background-color: $theme-main-graywhite;
}

// お知らせ　バナー
.top-notice-row {
  background-color: black;
}

.top-notice {
  left: 0;
  padding: 8px 4px;
  background-color: black;
  color: $theme-accent-white;
  width: 100%;
}


// ×マーク
.close-mark {
  top: 0;
  right: 25px;
}

// bootstrapのrowの左右マージンを打ち消す
.row {
  margin-left: 0;
  margin-right: 0;
}

// 海外サイト
.banner {
  width: 95%;
  height: auto;
}

// お問合せフォーム
.request-form {
  height: 120px;
  width: 100%;
  background-size: contain;
  background-image: url("/assets/images/request_bg.png");
}

// お問合せフォームボタン
.btn-request-form {
  border: 2px solid $theme-accent-white;
  background-color: $theme-accent-yellow;
  width: 15rem;
  border-radius: 10px;
  padding: 3px 20px;
  color: $theme-accent-white;

  &:hover {
    background-color: $theme-accent-lightyellow;
  }
}

// お知らせ　列
.top-notice-row {
}


// links 列
.links-row {
  padding-top: 2rem;
  background-color: $theme-main-white;
}

// request-form 列
.request-form-row {
  padding: 2rem 0;
  background-color: $theme-main-white;
}

// sns-icon 列
.sns-icons-row {
  background-color: $theme-main-white;
  padding: 40px 0 0;

  a {

    img {
      padding-right: 0.3rem;
    }

    span {
      font-size: 1.1rem;
      vertical-align: middle;
    }
  }
}

// top-banner-pc
.top-banner-pc {
  display: block !important;
}

// top-banner-sp
.top-banner-sp {
  display: none !important;
}

.store-info-pc {
  display: block !important;
}
.store-info-sp {
  display: none !important;
}

@media (max-width: 767px) {
  .top-wrapper {
  }
  // links 列
  .links-row {
    padding-bottom: 1rem;
  }

  // request-form 列
  .request-form-row {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    background-color: $theme-main-graywhite;
  }

  // sns-icon 列
  .sns-icons-row {
    a {
      width: 50%;
      margin-bottom: 1rem;
    }
  }

  // top-banner-pc
  .top-banner-pc {
    display: none !important;
  }
  // top-banner-sp
  .top-banner-sp {
    display: block !important;
  }

  .store-info-pc {
    display: none !important;
  }
  .store-info-sp {
    display: block !important;
  }
}
