// Variables
@import "../../../sass/variables";

.card {
  color: $theme-accent-black;
  margin: 10px 12px 0;
  padding: 0 10px 10px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%), 0 1px 5px 0 rgb(0 0 0 / 12%);
  border: none;
  min-width: 180px;
  max-width: 205px;

  .marker-row {
    height: 20px;
    position: relative;

    .marker {
      font-size: 0.6rem;
      margin-right: 3px;
      display: inline-flex;
      text-align: center;
      padding: 1px 10px;
      align-items: center;

      &.new {
        color: white;
        border: 1px solid black;
        background-color: black;
      }

      &.recommend {
        color: $theme-accent-white;
        border: 1px solid $theme-accent-yellow;
        background-color: $theme-accent-yellow;
      }

      &.limited {
        color: #fafafa;
        border: 1px solid #177E89;
        background-color: #177E89;
      }

      &.sale {
        color: white;
        border: 1px solid #DB162F;
        background-color: #DB162F;
      }
    }
  }

  .favorite-heart {
    position: absolute;
    bottom: 10px;
    right: 0;
    padding: 5px 10px;
    display: none;
    background-color: #ffffff94;
    border-radius: 30px;
  }

  .favorite-heart-check {
    display: inline-block;
  }

  .favorite-heart:hover {
    img {
      cursor: pointer;
      opacity: 0.5;
    }
  }

  img {
    margin: 8px auto 8px;
  }

  .item-image {
    width: 100%;
    height: auto;
    max-width: 150px;
  }

  .sold-out-msg {
    width: 100%;
    height: auto;
    max-width: 150px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }

  .left-just {
    white-space: nowrap;
    overflow: hidden;
  }

  .item-list {
    height: 50px;
    white-space: pre-wrap;
    margin: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

  .cart-btn {
    position: relative;

    input {
      color: $theme-accent-gray;
      border: 1px solid $theme-accent-gray;
      border-radius: 50vh;
      background-color: $theme-main-white;
      margin-top: 10px;
      font-size: 0.8rem;
      padding: 7px 0;
      width: 100%;
    }

    img {
      cursor: pointer;
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }

  .cart-add-btn {
    cursor: pointer;

    &:hover {
      border-color: $theme-accent-lightgray;
      color: $theme-accent-lightgray;

      + img {
        opacity: 0.5;
      }
    }
  }
}

// カテゴリー、商品名、販売元
.category_name, .product-name, .sales_agency {
  font-size: 0.9rem;
  font-weight: normal;
}

// 商品価格
.product-price {
  font-size: 1.1rem;
  color: $theme-accent-red;
}

// 販売価格(税込み)
.price-label {
  font-size: 0.7rem;
}

.cart-add-tooltip {
  position: relative;
  cursor: pointer;
  display: inline-block;

  .description {
    display: none;
    position: absolute;
    padding: 7px 20px;
    font-size: 12px;
    line-height: 1.6em;
    color: #fff;
    border-radius: 5px;
    background: #000;
    width: max-content;
    text-align: center;
    z-index: 9;
  }

  .description:before {
    content: "";
    position: absolute;
    top: -55%;
    left: 50%;
    border: 15px solid transparent;
    border-top: 15px solid #000;
    margin-left: -15px;
    transform: rotateZ(180deg);
  }

  :hover > .description {
    display: inline-block;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
  }
}

.disabled-gray{
  height: 40px;
}

.discount-label-card{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f72d2d;
  color: #fff;
  font-size: 11px;
  padding: 0 5px;
}

// スマートフォン対応
@media (max-width: 767px) {
  .card {
    margin-left: 4px;
    margin-right: 4px;
    min-width: 150px !important;
    max-width: 150px !important;

    .cart-btn {
      input {
        font-size: 0.8rem;
        line-height: 1.3;
        background-color: $theme-main-white;
        z-index: 5;
      }

      img {
        right: 8px;
        top: 11px;
      }
    }

    .cart-add-btn {
      height: 40px;
    }

    .sold-out-msg {
        p {
            left: 14px;
            top: 50px;
            font-size: 1.2rem;
        }
      }
  }
}
